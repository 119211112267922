// // src/ThemeToggle.js
// import React, { useState, useEffect } from 'react';
// import { Button } from 'antd';
// const ThemeToggle = () => {
//   const [isDarkMode, setIsDarkMode] = useState(false);

//   useEffect(() => {
//     // Check for saved user preference or system setting
//     const savedMode = localStorage.getItem('theme');
//     if (savedMode) {
//       setIsDarkMode(savedMode === 'dark');
//     } else {
//       setIsDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches);
//     }
//   }, []);

//   useEffect(() => {
//     // Apply the theme
//     document.body.className = isDarkMode ? 'dark-mode' : 'light-mode';
//     localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
//   }, [isDarkMode]);

//   return (
//     // <button onClick={() => setIsDarkMode(!isDarkMode)} className="btn btn-primary">
//     //   Toggle {isDarkMode ? 'Light' : 'Dark'} Mode
//     // </button>
//     <Button type={isDarkMode ? 'primary' : 'default'} onClick={() => setIsDarkMode(!isDarkMode)}>
//     {isDarkMode ? 'Enable Light' : ' Enable Dark'}
//   </Button>
//   );
// };

// export default ThemeToggle;


// src/ThemeToggle.js
import React, { useState, useEffect } from 'react';
import { Button } from 'antd';

const ThemeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Check for saved user preference or system setting
    const savedMode = localStorage.getItem('theme');
    if (savedMode) {
      return savedMode === 'dark';
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  useEffect(() => {
    // Apply the theme
    document.body.className = isDarkMode ? 'dark-mode' : 'light-mode';
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  return (
    <Button 
      type={isDarkMode ? 'primary' : 'default'} 
      onClick={() => {setIsDarkMode(prevMode => !prevMode); window.location.reload();}}
    >
      {isDarkMode ? 'Enable Light' : 'Enable Dark'}
    </Button>
  );
};

export default ThemeToggle;
