import React, { useState } from "react";
import { Button, Carousel, Modal, Spin, message } from "antd";
import { NavLink, useParams } from "react-router-dom";
import emailjs from '@emailjs/browser';
import AdSense from "../components/Adsence";
const contentStyle = {
  margin: 0,
  height: "360px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  // background: '#364d79',
};
const PS1 = (props) => {
  const [open, setOpen] = React.useState(false);
  const [openEmail, setEmailOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [gameImage, setGameImage] = useState("");
  const [gameScreenShots, setGameScreenShots] = useState("");
  const [gameDetail, setGameDetail] = useState("");
  const [gameSysDetail, setGameSysDetail] = useState("");
  const [gameparaDetail, setGameParaDetail] = useState("");
  const [downloadLink, setDownloadLink] = useState("");
  const [email, setEmail] = useState("");
  const params = useParams();
  // Inline styles
  const styles = {
    body: {
      fontFamily: "Arial, sans-serif",
      backgroundColor: "#f4f4f4",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      margin: 0,
    },
    container: {
      background: "white",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      width: "90%",
      maxWidth: "400px",
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    heading: {
      marginBottom: "20px",
      textAlign: "center",
    },
    input: {
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "16px",
    },
    button: {
      padding: "10px",
      border: "none",
      borderRadius: "4px",
      backgroundColor: "#28a745",
      color: "white",
      fontSize: "16px",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    buttonHover: {
      backgroundColor: "#218838",
    },
  };
  const showLoading = (data) => {
    console.log("data-------", JSON.stringify(data));
    // alert(JSON.stringify(data))
    setOpen(true);
    setLoading(true);
    setGameScreenShots(data.screenshots);
    setGameImage(data.posterImage);
    setGameDetail(data.title);
    setGameParaDetail(data.detail);
    setGameSysDetail(data.systemRequirements);
    setDownloadLink(data.downloadlink);
    // Simple loading mock. You should add cleanup logic in real world.
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  const onChangeSlider = (currentSlide) => {
    console.log(currentSlide);
  };
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [emailSending, setEmailSending] = useState(false)
  const handleSendEmail = (e) => {
    setEmailSending(true)
    e.preventDefault();

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = 'service_8iwyt3n';
    const templateId = 'template_6uragr8';
    const publicKey = 'mhYv3LO8lnfv3DxHm';

    // Create a new object that contains dynamic template params
    const templateParams = {
      to_email: email,
      // message: `Here is your download link: ${downloadLink}`,
      message: `Hi ${email},

                Thank you for choosing GameStasion! We’re excited to provide you with the resource you requested.

                You can download your game by clicking the link below:

                ${downloadLink}

                Stay connected with us on social media for updates, tips, and special offers:

                Facebook: https://www.facebook.com/share/KgS3pHK8H8fhVb9Q/
                Whatsapp_Channel: https://whatsapp.com/channel/0029VafdsajKWEKrMZ1QmA3e
                
                And Do Subscribe to our youtube channel for amazing content.
                Youtube: https://www.youtube.com/@SheraziTech-w3p
                `,
    };

    // Send the email using EmailJS
    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setName('');
        setEmail('');
        setMessage('');
        setEmailOpen(false)
        alert('Email sent successfully at '+ email)
        setEmailSending(false)
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setEmailSending(false)
      });
  }

  return (
    <div>
      {/* product section start */}
      <div className="product_section layout_padding">
        <div className="container">
        <div className="col-md-12">
                    <AdSense/>
              </div>
          <div className="row">
            <div className="col-sm-12">
              <h1 className="product_taital">Our PS1 Games</h1>
              <p className="product_text">
                Action-adventure game that redefined the Metroidvania genre with
                exploration and RPG elements.
              </p>
            </div>
          </div>
          <div className="product_section_2 layout_padding">
            <div className="row">
              {props.gameData.games.map((item) => {
                return (
                  <div className="col-lg-3 col-sm-6">
                    <div className="product_box">
                      <h4 className="bursh_text">{item.title}</h4>
                      <p className="lorem_text">
                        {/* The Witcher series lets players explore a rich fantasy world as Geralt of Rivia, facing tough choices and epic battles. */}
                        {item.detail}
                      </p>
                      <img src={item.posterImage} className="image_1" />
                      <div className="btn_main">
                      <div className="buy_bt" style={{cursor:"pointer"}} onClick={() => showLoading(item)}>
                      <NavLink  style={{backgroundColor:"#d3d3d3",borderRadius:25,padding:10,color:"black"}}>Download</NavLink>
                      
                         
                        </div>
                        {/* <h3 className="price_text">Price 3000</h3> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-md-12">
                    <AdSense/>
              </div>


            {/* <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="product_box">
                  <h4 className="bursh_text">Dragon Ball Z</h4>
                  <p className="lorem_text">
                    DBZ games immerse players in high-energy battles as they
                    control iconic characters.
                  </p>
                  <img src="images/DBZ.jpg" className="image_1" />
                  <div className="btn_main">
                    <div className="buy_bt">
                      <ul>
                        
                        <li>
                          <a
                            onClick={() => {
                              showLoading("images/DBZ.jpg", "Dragon Ball Z");
                            }}
                          >
                            Download
                          </a>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="product_box">
                  <h4 className="bursh_text">God of War</h4>
                  <p className="lorem_text">
                    God of War follows Kratos as he battles mythological foes
                    while navigating his role as a father in a richly woven
                    narrative.
                  </p>
                  <img src="images/War.jpg" className="image_1" />
                  <div className="btn_main">
                    <div className="buy_bt">
                      <ul>
                        
                        <li>
                          <a
                            onClick={() => {
                              showLoading("images/War.jpg", "God of War");
                            }}
                          >
                            Download
                          </a>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="product_box">
                  <h4 className="bursh_text">The Withcher</h4>
                  <p className="lorem_text">
                    The Witcher series lets players explore a rich fantasy world
                    as Geralt of Rivia, facing tough choices and epic battles.
                  </p>
                  <img src="images/Witcher.jpg" className="image_1" />
                  <div className="btn_main">
                    <div className="buy_bt">
                      <ul>
                        
                        <li>
                          <a
                            onClick={() => {
                              showLoading("images/Witcher.jpg", "The Witcher");
                            }}
                          >
                            Download
                          </a>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="product_box">
                  <h4 className="bursh_text">DMC3</h4>
                  <p className="lorem_text">
                    DMC features stylish action as players control demon hunter,
                    battling supernatural foes in a gothic world.
                  </p>
                  <img src="images/DMC3.jpg" className="image_1" />
                  <div className="btn_main">
                    <div className="buy_bt">
                      <ul>
                        
                        <li>
                          <a
                            onClick={() => {
                              showLoading("images/DMC3.jpg", "DMC3");
                            }}
                          >
                            Download
                          </a>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div> */}



            {/* <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="product_box">
                  <h4 className="bursh_text">Essential</h4>
                  <p className="lorem_text">
                    Essential perfume delivers pure elegance with a
                    subtle,understated fragrance with pure simplicity.
                  </p>
                  <img src="images/perfume4.jpg" className="image_1" />
                  <div className="btn_main">
                    <div className="buy_bt">
                      <ul>
                        <li>
                          <a href="#">Download</a>
                        </li>
                      </ul>
                    </div>
                    <h3 className="price_text">Price $30</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="product_box">
                  <h4 className="bursh_text">Chanel</h4>
                  <p className="lorem_text">
                    Chanel perfume exudes timeless sophistication, blending
                    classic elegance with modern allure.
                  </p>
                  <img src="images/perfume1.jpg" className="image_1" />
                  <div className="btn_main">
                    <div className="buy_bt">
                      <ul>
                        <li>
                          <a href="#">Download</a>
                        </li>
                      </ul>
                    </div>
                    <h3 className="price_text">Price $30</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="product_box">
                  <h4 className="bursh_text">Aqua</h4>
                  <p className="lorem_text">
                    Aqua perfume refreshes with a crisp, invigorating scent that
                    evokes the essence of fresh water.
                  </p>
                  <img src="images/perfume2.jpg" className="image_1" />
                  <div className="btn_main">
                    <div className="buy_bt">
                      <ul>
                        <li>
                          <a href="#">Download</a>
                        </li>
                      </ul>
                    </div>
                    <h3 className="price_text">Price $30</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="product_box">
                  <h4 className="bursh_text">Dolce and Gabana</h4>
                  <p className="lorem_text">
                    Dolce & Gabbana perfume captures luxurious elegance with
                    bold, captivating scents.
                  </p>
                  <img src="images/perfume3.jpg" className="image_1" />
                  <div className="btn_main">
                    <div className="buy_bt">
                      <ul>
                        <li>
                          <a href="#">Download</a>
                        </li>
                      </ul>
                    </div>
                    <h3 className="price_text">Price $30</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="seemore_bt">
              <a href="#">See More</a>
            </div> */}
          </div>
        </div>
      </div>
      {/* product section end */}
      {/* <Button type="primary" onClick={showLoading}>
        Open Modal
      </Button> */}
        <Modal
          title={<h1>{gameDetail}</h1>}
          footer={
            <Button
              type="primary"
              onClick={() => {
                setOpen(false);
                setLoading(false);
                setEmailOpen(true);
                setEmail("");
                // alert(downloadLink);
              }}
            >
              Download
            </Button>
          }
          loading={loading}
          open={open}
          onCancel={() => setOpen(false)}
          width="100%" // Set modal width to 100%
          bodyStyle={{ padding: "24px" }} // Optional: Adjust body padding
          style={{ top: 0 }} // Optional: Position at the top
          destroyOnClose // Optional: Clean up on close
        >
          {/* <h1>{gameDetail}</h1> */}
          {/* <div>
          <img src={gameImage} className="image_1" alt="Game" style={{ width: '100%', height: 'auto' }} />
        </div>
        <p>{gameparaDetail}</p>
        <p>{gameSysDetail}</p> */}
        <AdSense/>
          <h2>Screen Shots:</h2>
          <Carousel afterChange={onChangeSlider}>
            <div>
              <img
                src={gameScreenShots.screenshot1}
                className="image_1"
                alt="Game"
                style={contentStyle}
              />
            </div>
            <div>
              <img
                src={gameScreenShots.screenshot2}
                className="image_1"
                alt="Game"
                style={contentStyle}
              />
            </div>
            <div>
              <img
                src={gameScreenShots.screenshot3}
                className="image_1"
                alt="Game"
                style={contentStyle}
              />
            </div>
            {/* <div>
          <h3 style={contentStyle}>2</h3>
        </div>
        <div>
          <h3 style={contentStyle}>3</h3>
        </div> */}
          </Carousel>
          <div className="mt-3">
          <h3>Game Detail:</h3>
          <span  style={{textAlign:"justify"}}>{gameparaDetail}</span>
          </div>
          <div className="mt-3">
          <h3>System Requirement:</h3>
          <span style={{textAlign:"justify"}}>{gameSysDetail}</span>
          </div>
        </Modal>

      <Modal
        // title={<p>Loading Modal</p>}
        footer={
          // <Button
          //   type="submit"
          //   onClick={() => {
          //     // setEmailOpen(false);
          //     setLoading(false);
          //     handleSendEmail();
          //   }}
          // >
          //   Send
          // </Button>
          null
        }
        loading={loading}
        open={openEmail}
        onCancel={() => setEmailOpen(false)}
      >
        {/* <h1>{gameDetail}</h1> */}
        <div style={{ padding: "5%" }}>
          <div style={styles.container}>
            <form style={styles.form} onSubmit={handleSendEmail}>
              <h2 style={styles.heading}>Send Link to Email</h2>
              <input
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={styles.input}
              />
              <button type="submit" style={styles.button}>{emailSending ? <Spin /> : "Send"}</button>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PS1;
