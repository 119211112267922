import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
// import ThemeToggle from './ThemeToggle'; // Import your ThemeToggle component if needed

const Sitemap = () => {
  const active = 'active-class'; // Define your active class
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("theme");
    return savedMode
      ? savedMode === "dark"
      : window.matchMedia("(prefers-color-scheme: dark)").matches;
  });
  return (
    // <div className='p-5 d-flex'>
    //   <ul style={{backgroundColor:"red",padding:15}}>
    //     {/* Uncomment to add ThemeToggle functionality
    //     <li>
    //       <div onClick={() => setIsDarkMode(!isDarkMode)}>
    //         <ThemeToggle />
    //       </div>
    //     </li>
    //     */}
    //     <li>
    //       <NavLink
    //         to="/"
    //         className={({ isActive }) => (isActive ? active : "")}
    //         style={({ isActive }) => ({
    //           fontWeight: isActive ? "bold" : "normal",
    //         })}
    //       >
    //         Home
    //       </NavLink>
    //     </li>
    //     <li>
    //       <NavLink
    //         to="/ps1"
    //         className={({ isActive }) => (isActive ? active : "")}
    //         style={({ isActive }) => ({
    //           fontWeight: isActive ? "bold" : "normal",
    //         })}
    //       >
    //         PS1
    //       </NavLink>
    //     </li>
    //     <li>
    //       <NavLink
    //         to="/ps2"
    //         className={({ isActive }) => (isActive ? active : "")}
    //         style={({ isActive }) => ({
    //           fontWeight: isActive ? "bold" : "normal",
    //         })}
    //       >
    //         PS2
    //       </NavLink>
    //     </li>
    //     <li>
    //       <NavLink
    //         to="/ps3"
    //         className={({ isActive }) => (isActive ? active : "")}
    //         style={({ isActive }) => ({
    //           fontWeight: isActive ? "bold" : "normal",
    //         })}
    //       >
    //         PS3
    //       </NavLink>
    //     </li>
    //     <li>
    //       <NavLink
    //         to="/xbox"
    //         className={({ isActive }) => (isActive ? active : "")}
    //         style={({ isActive }) => ({
    //           fontWeight: isActive ? "bold" : "normal",
    //         })}
    //       >
    //         XBOX
    //       </NavLink>
    //     </li>
    //     <li>
    //       <NavLink
    //         to="/pc"
    //         className={({ isActive }) => (isActive ? active : "")}
    //         style={({ isActive }) => ({
    //           fontWeight: isActive ? "bold" : "normal",
    //         })}
    //       >
    //         PC
    //       </NavLink>
    //     </li>
    //     <li>
    //       <NavLink
    //         to="/articles"
    //         className={({ isActive }) => (isActive ? active : "")}
    //         style={({ isActive }) => ({
    //           fontWeight: isActive ? "bold" : "normal",
    //         })}
    //         onClick={closeNav}
    //       >
    //         Articles
    //       </NavLink>
    //     </li>
    //   </ul>
    // </div>
    <div className="col-sm-3">
    <div className="main p-3">
      {/* <h3 className="address_text">Best Games</h3> */}
      <h3 className="">Quick Links</h3>
      <div className='ml-2'>
      <div className='d-flex'>
      <NavLink
            to="/"
             className={!isDarkMode ? "ipsum_textDark" : "ipsum_text"}
            style={({ isActive }) => ({
              fontWeight: isActive ? "bold" : "normal",
            })}
          >
            Home
          </NavLink>
          {/* {alert(isDarkMode)} */}
      <NavLink
            to="/ps1"
            className={!isDarkMode ? "ipsum_textDark" : "ipsum_text"}
            style={({ isActive }) => ({
              fontWeight: isActive ? "bold" : "normal", marginLeft:"15px"
            })}
          >
            PS1
          </NavLink>
      <NavLink
            to="/ps2"
            className={!isDarkMode ? "ipsum_textDark" : "ipsum_text"}
            style={({ isActive }) => ({
              fontWeight: isActive ? "bold" : "normal", marginLeft:"15px"
            })}
          >
            PS2
          </NavLink>
          <NavLink
            to="/ps3"
             className={!isDarkMode ? "ipsum_textDark" : "ipsum_text"}
            style={({ isActive }) => ({
              fontWeight: isActive ? "bold" : "normal", marginLeft:"15px"
            })}
          >
            PS3
          </NavLink>
          <NavLink
            to="/xbox"
             className={!isDarkMode ? "ipsum_textDark" : "ipsum_text"}
            style={({ isActive }) => ({
              fontWeight: isActive ? "bold" : "normal", marginLeft:"15px"
            })}
          >
            XBOX
          </NavLink>
          <NavLink
            to="/pc"
             className={!isDarkMode ? "ipsum_textDark" : "ipsum_text"}
            style={({ isActive }) => ({
              fontWeight: isActive ? "bold" : "normal",marginLeft:"15px"
            })}
          >
            PC
          </NavLink>
      <NavLink
            to="/articles"
             className={!isDarkMode ? "ipsum_textDark" : "ipsum_text"}
            style={({ isActive }) => ({
              fontWeight: isActive ? "bold" : "normal",marginLeft:"15px"
            })}
          >
            Articles
          </NavLink>
      </div>
    </div>
    </div>
  </div>
  );
};

export default Sitemap;
