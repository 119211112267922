import React, { useEffect } from "react";
import PS1 from "./PS1";
import Articles from "./Articles";
import PS2 from "./PS2";
import PS3 from "./PS3";
import XBOX from "./XBOX";
import PC from "./PC";
import AdSense from "../components/Adsence";
const Home = (props) => {




  
  
  return (
    <div>
      {/* header section start */}
      {/* <HeaderNavbar/> */}
      {/* header section end */}
      {/* banner section start */}
        <AdSense/>
      <div className="banner_section layout_padding">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6">
                    <h1 className="banner_taital">DMC</h1>
                    <p className="banner_text">
                      Devil May Cry (DMC) games are renowned for their stylish
                      action and deep combat mechanics, combining fast-paced
                      gameplay with a captivating narrative. Players control
                      iconic characters like Dante and Nero, battling a variety
                      of demons and supernatural foes across beautifully
                      designed environments. The series is celebrated for its
                      over-the-top action sequences, intricate combo systems,
                      and the ability to customize characters, allowing for a
                      personalized playstyle. With a blend of gothic aesthetics
                      and engaging storytelling, DMC remains a beloved staple in
                      the action-adventure genre.
                    </p>
                    {/* <div className="read_bt">
                      <a style={{color:"white"}}>See more</a>
                    </div> */}
                  </div>
                  <div className="col-sm-6">
                    <div className="banner_img">
                      <img src="images/DMC1.jpg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6">
                    <h1 className="banner_taital">DMC2</h1>
                    <p className="banner_text">
                      Devil May Cry (DMC) games are renowned for their stylish
                      action and deep combat mechanics, combining fast-paced
                      gameplay with a captivating narrative. Players control
                      iconic characters like Dante and Nero, battling a variety
                      of demons and supernatural foes across beautifully
                      designed environments. The series is celebrated for its
                      over-the-top action sequences, intricate combo systems,
                      and the ability to customize characters, allowing for a
                      personalized playstyle. With a blend of gothic aesthetics
                      and engaging storytelling, DMC remains a beloved staple in
                      the action-adventure genre.
                    </p>
                    {/* <div className="read_bt">
                      <a href="/">Download</a>
                    </div> */}
                  </div>
                  <div className="col-sm-6">
                    <div className="banner_img">
                      <img src="images/DMC2.jpg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="carousel-item">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <h1 className="">Chanel</h1>
                <p className="banner_text">Chanel perfume embodies timeless elegance and sophistication with its meticulously crafted scents. Each fragrance, from the iconic Chanel No. 5 to the bold Coco Mademoiselle, offers a unique blend of notes that evoke distinct emotions. Wearing Chanel is not just about fragrance—it's about making a memorable impression of style and grace.</p>
                <div className="read_bt"><a href="#">Buy Now</a></div>
              </div>
              <div className="col-sm-6">
                <div className="banner_img"><img src="images/DMC3.jpg" /></div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <h1 className="banner_taital">Essential</h1>
                <p className="banner_text">Essential perfume captures the essence of simplicity and purity with its carefully curated blend of aromatic notes. It offers a refined, subtle fragrance that enhances natural elegance without overwhelming. Ideal for daily wear, Essential perfume is a testament to understated sophistication.</p>
                <div className="read_bt"><a href="#">Buy Now</a></div>
              </div>
              <div className="col-sm-6">
                <div className="banner_img"><img src="images/perfume3.jpg" /></div>
              </div>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </div>
      {/* banner section end */}
      
      {/* <AdSense/> */}
      {/* product section start */}
      <PS3 
      ps3gameData = {props.ps3gameData}/>
     
      
      <AdSense/>
      {/* <PS2 />
      <PS3 />
      <XBOX />
      <PC /> */}
      {/* product section end */}
      {/* about section start */}
      <Articles />
      {/* <div className="col-md-12">
                    <AdSense/>
              </div> */}
      {/* about section end */}
      {/* customer section start */}
      <div className="customer_section layout_padding">
        <div className="container">
          <div className="row ">
          <div className="col-md-12">
                    <AdSense/>
              </div>
            <div className="col-sm-12">
              <h1 className="customer_taital">Trending Games</h1>
            </div>
          </div>
          <div className="row d-flex">
          <div className="col-md-1">
                    <AdSense/>
              </div>
          <div id="main_slider" className="carousel slide col-md-10 mb-5" data-ride="carousel">
            <div className="carousel-inner ">
              <div className="carousel-item active">
                <div className="client_section_2">
                  <div className="client_main">
                    <div className="client_left">
                      <div className="client_img">
                        <img src="images/witcherpc.jpg" />
                      </div>
                    </div>
                    <div className="client_right">
                      <h3 className="name_text">The Witcher</h3>
                      <p className="dolor_text">
                      An open-world RPG where players control Geralt of Rivia in a richly detailed fantasy world filled with quests and choices.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="client_section_2">
                  <div className="client_main">
                    <div className="client_left">
                      <div className="client_img">
                        <img src="images/War.jpg" />
                      </div>
                    </div>
                    <div className="client_right">
                      <h3 className="name_text">God of the war</h3>
                      <p className="dolor_text">
                      God of War follows Kratos, a relentless Spartan warrior seeking revenge against the gods of Olympus. It features brutal combat, epic storytelling, and stunning visuals throughout.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="client_section_2">
                  <div className="client_main">
                    <div className="client_left">
                      <div className="client_img">
                        <img src="images/lastofusps3.jpg" />
                      </div>
                    </div>
                    <div className="client_right">
                      <h3 className="name_text">The Last of Us</h3>
                      <p className="dolor_text">
                      In The Last of Us, players navigate a post-apocalyptic world as Joel and Ellie, battling infected creatures and human adversaries while exploring themes of survival and love.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#main_slider"
              role="button"
              data-slide="prev"
            >
              <i className="fa fa-angle-left" />
            </a>
            <a
              className="carousel-control-next"
              href="#main_slider"
              role="button"
              data-slide="next"
            >
              <i className="fa fa-angle-right" />
            </a>
          </div>
          <div className="col-md-1">
                    <AdSense/>
              </div>
            </div>
        </div>
      </div>
      {/* customer section end */}
      {/* contact section start */}
      {/* <div className="contact_section layout_padding">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <h1 className="contact_taital">Get In Touch</h1>
          <p className="contact_text">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation  eu </p>
        </div>
        <div className="col-md-6">
          <div className="contact_main">
            <div className="contact_bt"><a href="#">Contact Form</a></div>
            <div className="newletter_bt"><a href="#">Newletter</a></div>
          </div>
        </div>
      </div>
    </div>
    <div className="map_main">
      <div className="map-responsive">
        <iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA0s1a7phLN0iaD6-UE7m4qP-z21pH0eSc&q=Rawalpindi" width={600} height={400} frameBorder={0} style={{border: 0, width: '100%'}} allowFullScreen />
      </div>
    </div>
  </div> */}
      {/* contact section end */}
    </div>
  );
};

export default Home;
