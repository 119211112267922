import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { NavLink} from 'react-router-dom'
import { Button } from 'antd';
import ThemeToggle from '../../ThemeToggle';
const HeaderNavbar = () => {
    useEffect(() => {
        // Custom JavaScript that relies on jQuery
        $('#mySidenav').css('width', '0'); // Initial setup, for example
      }, []);
    
      const openNav = () => {
        document.getElementById('mySidenav').style.width = '100%';
      };
    
      const closeNav = () => {
        document.getElementById('mySidenav').style.width = '0';
      };
      // const [isDarkMode, setIsDarkMode] = useState(false);

  // useEffect(() => {
    // Check for saved user preference or system setting
    const checkTheme = () => {
    let savedMode = localStorage.getItem('theme');
    if (savedMode) {
      setIsDarkMode(savedMode === 'dark');
    } else {
      setIsDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches);
    }
  }
  // }, []);

  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("theme");
    return savedMode
      ? savedMode === "dark"
      : window.matchMedia("(prefers-color-scheme: dark)").matches;
  });

  useEffect(() => {
    // Apply the theme on initial render
    document.body.className = isDarkMode ? "dark-mode" : "light-mode";
    localStorage.setItem("theme", isDarkMode ? "dark" : "light");
  }, [isDarkMode]);
  let active={
        color:"red",
        fontWeight:"bold"
    }
    return (
      <div className={isDarkMode ? "header_sectionDark" : "header_section"}>
        <div className="container-fluid">
          <nav
            className={
              isDarkMode
                ? "navbar navbar-dark bg-dark justify-content-between"
                : "navbar navbar-light bg-light justify-content-between"
            }
          >
            <div
              id="mySidenav"
              className="sidenav"
              style={{ color: isDarkMode ? "white" : "black" }}
            >
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={closeNav}
              >
                ×
              </a>
              <a href="/" onClick={closeNav}>
                Home
              </a>
              {/* <NavLink
                to="/"
                className={({ isActive }) => (isActive ? active : "")}
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                onClick={closeNav}
              >
                Home
              </NavLink> */}
              <NavLink
                to="/ps1"
                className={({ isActive }) => (isActive ? active : "")}
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                onClick={closeNav}
              >
                PS1
              </NavLink>
              <NavLink
                to="/ps2"
                className={({ isActive }) => (isActive ? active : "")}
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                onClick={closeNav}
              >
                PS2
              </NavLink>
              <NavLink
                to="/ps3"
                className={({ isActive }) => (isActive ? active : "")}
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                onClick={closeNav}
              >
                PS3
              </NavLink>
              <NavLink
                to="/xbox"
                className={({ isActive }) => (isActive ? active : "")}
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                onClick={closeNav}
              >
                XBOX
              </NavLink>
              <NavLink
                to="/pc"
                className={({ isActive }) => (isActive ? active : "")}
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                onClick={closeNav}
              >
                PC
              </NavLink>
              {/* <a href="about.html">About</a> */}
              <NavLink
                to="/articles"
                className={({ isActive }) => (isActive ? active : "")}
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                onClick={closeNav}
              >
                Articles
              </NavLink>
              {/* <a href="client.html">Client</a> */}
              {/* <NavLink
                to="/contactus"
                className={({ isActive }) => (isActive ? active : "")}
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                onClick={closeNav}
              >
                Contact
              </NavLink> */}
            </div>
            <span
              className="toggle_icon d-flex"
              style={{ alignItems: "center", cursor: "pointer" }}
            >
              {isDarkMode ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  class="bi bi-list"
                  viewBox="0 0 16 16"
                  onClick={openNav}
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                  />
                </svg>
              ) : (
                <img src="images/toggle-icon.png" onClick={openNav} />
              //   <svg
              //   xmlns="http://www.w3.org/2000/svg"
              //   width="30"
              //   height="30"
              //   fill="currentColor"
              //   class="bi bi-list"
              //   viewBox="0 0 16 16"
              //   onClick={openNav}
              // >
              //   <path
              //     fill-rule="evenodd"
              //     d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
              //   />
              // </svg>
              )}
              {/* <NavLink
                className="logo ml-5 bold"
                to={"/"}
                style={{
                  color: isDarkMode ? "white" : "black",
                  fontSize: "25px",
                }}
              >
                GameStation
              </NavLink> */}
              <a
                className="logo ml-5 bold"
                href="/"
                style={{
                  color: isDarkMode ? "white" : "black",
                  fontSize: "25px",
                }}
              >
                GameStasion
              </a>
            </span>
            {/* <a className="logo" href="index.html"><img src="images/logo.png" /></a> */}
            <form className="form-inline ">
              <div className={isDarkMode ? "login_textDark" : "login_text"}>
                <ul>
                  {/* <li>
                    <div
                      onClick={() => {
                        setIsDarkMode(!isDarkMode);
                      }}
                    >
                      <ThemeToggle />
                    </div>
                  </li> */}
                  <li>
                    <NavLink
                      to="/ps1"
                      className={({ isActive }) => (isActive ? active : "")}
                      style={({ isActive }) => ({
                        fontWeight: isActive ? "bold" : "normal",
                      })}
                    >
                      PS1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/ps2"
                      className={({ isActive }) => (isActive ? active : "")}
                      style={({ isActive }) => ({
                        fontWeight: isActive ? "bold" : "normal",
                      })}
                    >
                      PS2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/ps3"
                      className={({ isActive }) => (isActive ? active : "")}
                      style={({ isActive }) => ({
                        fontWeight: isActive ? "bold" : "normal",
                      })}
                    >
                      PS3
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/xbox"
                      className={({ isActive }) => (isActive ? active : "")}
                      style={({ isActive }) => ({
                        fontWeight: isActive ? "bold" : "normal",
                      })}
                    >
                      XBOX
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/pc"
                      className={({ isActive }) => (isActive ? active : "")}
                      style={({ isActive }) => ({
                        fontWeight: isActive ? "bold" : "normal",
                      })}
                    >
                      PC
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/articles"
                      className={({ isActive }) => (isActive ? active : "")}
                      style={({ isActive }) => ({
                        fontWeight: isActive ? "bold" : "normal",
                      })}
                      onClick={closeNav}
                    >
                      Articles
                    </NavLink>
                  </li>
                </ul>
              </div>
            </form>
          </nav>
        </div>
      </div>
    );
}

export default HeaderNavbar
